import React from 'react';
import { FaEnvelope, FaGitlab, FaLinkedin } from 'react-icons/fa'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Darryl Canseco</h1>
        <p>Husband &middot; Father &middot; Software Engineer &middot; Technology Enthusiast &middot; Marvel Cinematic Universe Fan</p>
        <div className="socialmedia-container">
          <a href="https://www.linkedin.com/in/djcanseco/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={36} /></a>
          <a href="https://gitlab.com/djcanseco" target="_blank" rel="noopener noreferrer"><FaGitlab size={36} /></a>
          <a href="mailto:djcanseco@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope size={36} /></a>
        </div>
      </header>
    </div>
  );
}

export default App;
